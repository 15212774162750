import React from 'react';
import { get } from 'lodash';
import App from '../../../common/components/app';
import CategoriesLink from '../../../common/components/categories-link';
import CategoryDesktopHeader from '../../../common/components/category-desktop-header';
import CategoryMobileHeader from '../../../common/components/category-mobile-header';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import MessageRoot from '../../components/messages/message-root';
import ModalRoot from '../../components/modals/modal-root';
import { MatchedRoute } from './use-matched-route';

export const WrapComponent =
  (Component: React.FC<{ params: any }>) => (props: MatchedRoute) =>
    (
      <App
        main={<Component params={props.params} />}
        modalRoot={<ModalRoot />}
        messageRoot={<MessageRoot />}
        popoverRoot={<PopoverRoot />}
        mobileHeader={<CategoryMobileHeader />}
        mobileMenu={<CategoriesLink />}
        desktopHeader={
          <CategoryDesktopHeader
            postSlug={get(props, 'params.postSlug', null)}
          />
        }
      />
    );
