import React, { Suspense, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import {
  EXPERIMENTS,
  isExperimentEnabled,
  resolveId,
} from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import Post from '../../../common/components/post';
import PrintWrapper from '../../../common/components/print-wrapper';
import {
  useActions,
  useSelector,
} from '../../../common/components/runtime-context';
import { useFeedMetadataSettings } from '../../../common/hooks/use-feed-metadata-settings';
import useFontClassName from '../../../common/hooks/use-font-class-name';
import useOnScreen from '../../../common/hooks/use-on-screen';
import { getRoute } from '../../../common/router/router-selectors';
import { getPostByIdOrSlug } from '../../../common/selectors/post-selectors';
import { isInPostPreview } from '../../../common/services/detect-route';
import { isInWix } from '../../../common/services/is-in-wix';
import scrollToContent from '../../../common/services/scroll-to-content';
import { resolvePostSlug } from '../../../common/services/slug';
import {
  getCommentId,
  isEditor as getIsEditor,
  isSeo as getIsSeo,
  isSSR as getIsSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getIsPostLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { NormalizedPost } from '../../../common/types';
import PostPageLayout from '../../components/post-page-layout';
import ReadingTimeListener, {
  OnScrollHandler,
  OnTabVisibilityChangeHandler,
} from '../../components/reading-time-listener/use-reading-time-listener';
import { RoutePostParams } from '../../constants/routes';
import { getReadingSessionId } from '../../selectors/reading-session-id-selector';
import { PostPageWixComments } from './post-page-wix-comments-safe';
import RelevantPosts from './relevant-posts';
import styles from './post-page.scss';
import { PostPageWixCommentsSeo } from './post-page-wix-comments-with-seo';

type Props = {
  params: RoutePostParams;
};

const PostPage = (props: Props) => {
  const { post, postSlug, readingSessionId, isSSR, isPostLoaded } =
    usePostPageSlice(props);
  const {
    relatedPostsLabelKey,
    showComments,
    showRelatedPosts,
    showRecentPosts,
  } = useFeedMetadataSettings({ post });
  const { contentFontClassName } = useFontClassName();
  const actions = useActions();
  const [forceComments, setForceComments] = React.useState(false);
  const commentsRef = React.useRef<HTMLDivElement>(null);

  const biElement = useBiElement({
    post,
    biActiveTabChanged: actions.biActiveTabChanged,
    biPostScrolled: actions.biPostScrolled,
    readingSessionId,
    slug: postSlug,
    trackEvent: actions.trackEvent,
    onSlugChange: () => {
      scrollToContent(styles.postPage);
    },
  });

  return (
    <PostPageLayout
      className={classNames(styles.postPage, contentFontClassName)}
      data-hook="post-page"
    >
      {!isSSR && !isInWix() && biElement}
      <AnimatedLoader isLoading={!isPostLoaded && isEmpty(post)}>
        <div className={styles.post}>
          <PrintWrapper>
            <Post
              key={resolveId(post)}
              post={post}
              isInPostPage
              onRatingsDisplayClick={() => {
                if (!forceComments) {
                  setForceComments(true);
                }

                requestAnimationFrame(() => {
                  commentsRef.current?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                  });
                });
              }}
            />
          </PrintWrapper>
          <AppLoaded key={post?.slug} />
        </div>
        <RelevantPosts
          post={post}
          showRecentPosts={showRecentPosts}
          showRelatedPosts={showRelatedPosts}
          relatedPostsLabelKey={relatedPostsLabelKey}
        />
        <CommentsSection
          params={props.params}
          commentsRef={commentsRef}
          forceComments={forceComments}
          showComments={showComments}
        />
      </AnimatedLoader>
    </PostPageLayout>
  );
};

type CommentsSectionProps = React.FC<
  React.ComponentProps<typeof PostPage> & {
    showComments: boolean;
    forceComments: boolean;
    commentsRef: React.RefObject<HTMLDivElement>;
  }
>;

const CommentsSection: CommentsSectionProps = (props) => {
  const { post, isSeo, isEditor, isSSR, commentId } =
    useCommentsSectionSlice(props);
  const canSeePost = post?.canSeePaidContent !== false;
  const renderComments = !isSeo && canSeePost && props.showComments && post?.id;
  const { isOnScreen, ref } = useOnScreen(undefined, isEditor);
  const actions = useActions();

  const isPostPageCommentsSeoEnabled = useSelector((state) =>
    isExperimentEnabled(state, EXPERIMENTS.POST_PAGE_COMMENTS_SEO),
  );

  if (isSeo && isPostPageCommentsSeoEnabled) {
    return (
      <div ref={props.commentsRef}>
        <PostPageWixCommentsSeo post={post} />
      </div>
    );
  }


  if (!renderComments) {
    return null;
  }

  if (!isSSR && commentId) {
    actions.initWixCommentsController();

    return (
      <div ref={props.commentsRef}>
        <Suspense fallback={<AnimatedLoader isLoading />}>
          <PostPageWixComments post={post} />
        </Suspense>
      </div>
    );
  }

  if (!isOnScreen && !props.forceComments) {
    return <div ref={ref as any} />;
  }

  actions.initWixCommentsController();

  return (
    <div ref={props.commentsRef}>
      <Suspense fallback={<AnimatedLoader isLoading />}>
        <PostPageWixComments post={post} />
      </Suspense>
    </div>
  );
};

type PostPageBiParams = {
  biActiveTabChanged: (payload: any) => void;
  biPostScrolled: (payload: any) => void;
  post?: NormalizedPost;
  readingSessionId: string;
  slug?: string;
  trackEvent: (eventName: string, params: any) => void;
  onSlugChange: () => void;
};

const useBiElement = ({
  biActiveTabChanged,
  biPostScrolled,
  onSlugChange,
  post,
  readingSessionId,
  slug,
  trackEvent,
}: PostPageBiParams) => {
  const lastSlugRef = useRef(slug);
  const slugOnChangeRef = useRef(onSlugChange);

  const blogPostViewTrackEvent = useCallback(() => {
    trackEvent('BlogPostView', {
      event: 'BlogPostView',
      eventCategory: 'Wix Blog',
      eventAction: 'BlogPostView',
      eventLabel: post?.title,
      origin: 'Wix Blog',
    });
  }, [post?.title, trackEvent]);

  useEffect(() => {
    blogPostViewTrackEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastSlugRef.current !== slug && slug) {
      lastSlugRef.current = slug;
      blogPostViewTrackEvent();
      slugOnChangeRef.current();
    }
  }, [slug, blogPostViewTrackEvent]);

  const handleScroll: OnScrollHandler = useCallback(
    (event) => {
      biPostScrolled({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biPostScrolled],
  );

  const handleTabVisibilityChange: OnTabVisibilityChangeHandler = useCallback(
    (event) => {
      biActiveTabChanged({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biActiveTabChanged],
  );

  return (
    <ReadingTimeListener
      getContentContainer={getPostContentContainer}
      onScroll={handleScroll}
      onTabVisibilityChange={handleTabVisibilityChange}
      readingSessionId={readingSessionId}
    />
  );
};

const getPostContentContainer = () => {
  return document.querySelector('[data-hook="post"]')?.getBoundingClientRect();
};

const usePostPageSlice = (ownProps: Props) => {
  return useSelector((state) => {
    const postSlug =
      'postId' in ownProps.params
        ? ownProps.params.postId
        : resolvePostSlug(ownProps.params);

    const useDraft = isInPostPreview(getRoute(state));
    let post = getPostByIdOrSlug(state, postSlug)!;

    post = useDraft ? { ...post, ...(post?.draft || {}) } : post;

    return {
      post,
      postSlug,
      isPostLoaded: getIsPostLoaded(state, postSlug),
      isSSR: getIsSSR(state),
      readingSessionId: getReadingSessionId(state),
    };
  });
};

const useCommentsSectionSlice = (ownProps: Props) => {
  return useSelector((state) => {
    const postSlug =
      'postId' in ownProps.params
        ? ownProps.params.postId
        : resolvePostSlug(ownProps.params);
    const useDraft = isInPostPreview(getRoute(state));
    let post = getPostByIdOrSlug(state, postSlug)!;

    post = useDraft ? { ...post, ...(post?.draft || {}) } : post;

    return {
      post,
      isEditor: getIsEditor(state),
      isSeo: getIsSeo(state),
      isSSR: getIsSSR(state),
      commentId: getCommentId(state),
    };
  });
};

export default PostPage;
