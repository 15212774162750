import React from 'react';
import PropTypes from 'prop-types';
import { getCategories } from '@wix/communities-blog-client-common';
import Header from '../header';
import { connect } from '../runtime-context';

const CategoryMobileHeader = ({ categories, menu, ...otherProps }) =>
  categories.length ? (
    <Header menu={menu} {...otherProps} />
  ) : (
    <Header {...otherProps} />
  );

CategoryMobileHeader.propTypes = {
  categories: PropTypes.array,
  menu: PropTypes.node,
};

const mapStateToProps = (state) => ({
  categories: getCategories(state),
});

export default connect(mapStateToProps)(CategoryMobileHeader);
