import '../../../rich-content/components/rich-content-editor/rich-content-editor.global.scss';
import React, { useEffect, useRef } from 'react';
import { I18nextProvider } from '@wix/yoshi-flow-editor';
import BlogPushNotifications from '../../../common/components/blog-push-notifications';
import { FastFormProvider } from '../../../common/components/fast-form';
import { getFastFormSubmitAction } from '../../../common/components/fast-form/store/fast-form-selectors';
import PermissionsProvider from '../../../common/components/permissions-provider';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { withRootProps } from '../../../common/components/root-props/with-root-props';
import { useSelector } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import WindowResizeListener from '../../../common/components/window-resize-listener';
import {
  getPinterestEnabled,
  getIsCreatedWithResponsiveEditor,
} from '../../../common/selectors/app-settings-selectors';
import { initI18n } from '../../../common/services/init-i18n';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import pinterest from '../../../common/services/pinterest';
import {
  getLanguage,
  isSeo as getIsSeo,
  getIsMobile,
  isEditor as getIsEditor,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import PostPushNotifications from '../../components/post-push-notifications';
import Router from '../router';
import styles from './app-root.scss';

function AppRoot() {
  const {
    language,
    translations,
    isMobile,
    isPinterestEnabled,
    addResizeListener,
    submitAction,
    isSeo,
  } = useSlice();

  useEffect(() => {
    pinterest.init({ language, isMobile, isPinterestEnabled });

    return () => {
      pinterest.removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastLanguageRef = useRef<any>(undefined);
  const i18nRef = useRef<any>(undefined);

  if (lastLanguageRef.current !== language) {
    lastLanguageRef.current = language;
    i18nRef.current = initI18n(language, translations);
  }

  return (
    <ResponsiveListener dataHook="post-page-root" className={styles.root}>
      <PermissionsProvider>
        <I18nextProvider i18n={i18nRef.current}>
          <FastFormProvider submitAction={submitAction}>
            <Router />
            {addResizeListener && <WindowResizeListener />}
            <BlogPushNotifications />
            <PostPushNotifications />
          </FastFormProvider>
        </I18nextProvider>
      </PermissionsProvider>
    </ResponsiveListener>
  );
}

function useSlice() {
  const language = useSelector(getLanguage);
  const translations = useSelector(getTranslations);
  const isMobile = useSelector(getIsMobile);
  const isPinterestEnabled = useSelector(getPinterestEnabled);
  const submitAction = useSelector(getFastFormSubmitAction);
  const isSeo = useSelector(getIsSeo);

  const isInClassicEditor = useSelector(
    (state) => getIsEditor(state) && !getIsCreatedWithResponsiveEditor(state),
  );

  return {
    language,
    translations,
    isSeo,
    isMobile,
    isPinterestEnabled,
    submitAction,
    addResizeListener: isInClassicEditor,
  };
}

export default withRootProps(withReduxStore(AppRoot));
