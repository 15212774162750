import { find } from 'lodash';
import { createSelector } from 'reselect';
import {
  getCategoryPath,
  getFeedTitle,
  getCategories,
} from '@wix/communities-blog-client-common';
import { getCurrentMatchPathname } from '../router/router-selectors';
import * as getHeaderLinksService from '../services/get-header-links';
import { getViewerAllPostsLabelTranslation } from './viewer-all-post-label-translation-selectors';

export const getFeedAndCategoryLinks = createSelector(
  getCategories,
  getFeedTitle,
  getViewerAllPostsLabelTranslation,
  getCategoryPath,
  (categories, feedTitle, translatedFeedTitle, categoryPath) =>
    getHeaderLinksService.getHeaderLinks({
      categories,
      feedTitle: translatedFeedTitle || feedTitle,
      categoryPath,
      withFeedLink: true,
    }),
);

export const getActiveLink = createSelector(
  getFeedAndCategoryLinks,
  getCurrentMatchPathname,
  (links, currentPath) =>
    find(links, (link) => link.path === currentPath) || links[0],
);
