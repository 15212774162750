import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import Loader from '../../../common/components/loader';

export const WixRicosViewerNextLazy = loadable(
  () => /* webpackChunkName: "ricos-viewer-next" */ import('@wix/ricos-viewer'),
  {
    resolveComponent: (components: any) => components.WixRicosViewerNext,
    fallback: <Loader />,
  },
);
